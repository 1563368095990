// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "../reveals/TextReveal"
import BlockWrapper from "../technical/BlockWrapper"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      showHeadline="true"
      showButtons="true"
      blockDirection="vertical"
      className=""
    >
      <div className="-mx-grid">
        <TextReveal
          className={`${block.flow === "true" ? "lg:w-2/3" : ""} px-grid`}
        >
          <Richtext
            flow={block.flow}
            text={block.text}
            className={block.text_size === "text-lg" ? block.text_size : ""}
          />
        </TextReveal>
      </div>
    </BlockWrapper>
  )
}

export default TextBlock
